import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FormMedia = ({ sendDataToBackend, handleNext, handleBack , currentStep}) => {
  const [submited, setSubmited] = useState(false);
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [textareaRows3, setTextAreaRows3] = useState({});
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const mainFormAnswers = JSON.parse(localStorage.getItem("answers"));
  const [selectedStructures, setSelectedStructures] = useState(
    mainFormAnswers.structure
  );
  const [showSubmitButton, setShowSubmitButton] = useState(false); 

  useEffect(() => {
    if (currentStep === selectedStructures.length - 1) {
      setShowSubmitButton(true);
    } else {
      setShowSubmitButton(false);
    }
  }, [ currentStep, selectedStructures]);
  const [answersForm, setAnswersForm] = useState(() => {
    const storedAnswers = localStorage.getItem("answersForm");
    return storedAnswers
      ? JSON.parse(storedAnswers)
      : {
          experience_Media: "",
          stack_Media: {},
          moreStack_Media: "",
          group_Media: "",
          post_Media: "",
          projects_Media:"",
          inspiration_Media: "",
          photos_Media: "",
          work_Media: "",
        };
  });

  const handleChange = (e) => {
    const { name, value, checked, scrollHeight, clientHeight } = e.target;
    const uniqueId = e.target.id;

    if (scrollHeight > clientHeight) {
      e.target.rows += 1;
    }
    if (e.target.type === "checkbox") {
      setAnswersForm((prevState) => {
        if (checked) {
          return {
            ...prevState,
            stack_Media: {
              ...prevState.stack_Media,
              [name]: value,
            },
          };
        } else {
          const { [name]: removedValue, ...rest } = prevState.stack_Media;
          return {
            ...prevState,
            stack_Media: {
              ...rest,
            },
          };
        }
      });
    } else {
      setAnswersForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    setTextAreaRows3((prevState) => ({
      ...prevState,
      [uniqueId]: e.target.rows,
    }));
    localStorage.setItem(
      "textareaRows3",
      JSON.stringify({ ...textareaRows3, [uniqueId]: e.target.rows })
    );    localStorage.setItem("answersForm", JSON.stringify(answersForm));
  };

  useEffect(() => {
    const savedRows = localStorage.getItem("textareaRows3");
    if (savedRows) {
      const textareas = document.querySelectorAll(".dynamic-textarea");
      textareas.forEach((textarea) => {
        const name = textarea.getAttribute("name");
        textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1);
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("answersForm", JSON.stringify(answersForm));
  }, [answersForm]);

  useEffect(() => {
    const storedAnswers = localStorage.getItem("answersForm");
    if (storedAnswers) {
      setAnswersForm(JSON.parse(storedAnswers));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(answersForm).filter(
      (key) =>
        typeof answersForm[key] === "string" && answersForm[key].trim() === ""
    );
    if (emptyFields.length > 0) {
      setError("Заполните все поля");
      setIsEmptyField(true); 
    } else {
      setError("");
      if (currentStep === selectedStructures.length - 1) {
        sendDataToBackend();
        setSubmited(true);
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      <div className="Form Form_Media">
        <form onSubmit={handleSubmit} className="survival-school-form">
          <h2 className="p_Media">Вопросы на Media</h2>

          <div>
            <label htmlFor="experience_Media">
              Расскажи про свой опыт в сфере медиа
            </label>
            <textarea
              id="experience_Media"
              name="experience_Media"
              className="dynamic-textarea"

              value={answersForm.experience_Media}
              onChange={handleChange}
              rows={textareaRows3["experience_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="stack_Media">
              В каких программах ты работаешь?
            </label>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="photoshop"
                name="photoshop"
                value="photoshop"
                style={{    width: '20px'}}
                onChange={handleChange}
              />
              <label for="photoshop">Photoshop</label>
            </div>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="premierpro"
                name="premierpro"
                style={{    width: '20px'}}
                value="premierpro"
                onChange={handleChange}
              />
              <label for="premierpro">Adobe Premier Pro</label>
            </div>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="illustrator"
                name="illustrator"
                style={{    width: '20px'}}
                onChange={handleChange}
                value="illustrator"
              />
              <label for="illustrator">Adobe Illustrator</label>
            </div>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="lunacy"
                name="lunacy"
                style={{    width: '20px'}}
                onChange={handleChange}
                value="lunacy"
              />
              <label for="lunacy">Lunacy</label>
            </div>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="photopea"
                name="photopea"
                style={{    width: '20px'}}
                onChange={handleChange}
                value="photopea"
              />
              <label for="photopea">Photopea</label>
            </div>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="figma"
                name="figma"
                style={{    width: '20px'}}
                onChange={handleChange}
                value="figma"
              />
              <label for="figma">Figma</label>
            </div>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="canva"
                name="canva"
                style={{    width: '20px'}}
                onChange={handleChange}
                value="canva"
              />
              <label for="canva">Canva</label>
            </div>
            <div className="tool">
              <input
                className="media__checkbox"
                type="checkbox"
                id="capcut"
                name="capcut"
                style={{    width: '20px'}}
                onChange={handleChange}
                value="capcut"
              />
              <label for="capcut">CapCut</label>
            </div>
          </div>
          <div>
            <label htmlFor="moreStack_Media">
              Если ты работаешь в другой(их) программе(ах) напиши про них ниже
            </label>
            <textarea
              id="moreStack_Media"
              name="moreStack_Media"
              className="dynamic-textarea"

              value={answersForm.moreStack_Media}
              onChange={handleChange}
              rows={textareaRows3["moreStack_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="projects_Media">Прикрепи ссылку на примеры своих работ</label>
            <textarea
              id="projects_Media"
              name="projects_Media"
              className="dynamic-textarea"

              value={answersForm.projects_Media}
              onChange={handleChange}
              rows={textareaRows3["projects_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="group_Media">
              Придумай, как можно было бы разнообразить группу ITC в ВК
            </label>
            <textarea
              id="group_Media"
              name="group_Media"
              className="dynamic-textarea"

              value={answersForm.group_Media}
              onChange={handleChange}
              rows={textareaRows3["group_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="post_Media">
              Что по твоему является залогом успешного поста?
            </label>
            <textarea
              id="post_Media"
              name="post_Media"
              className="dynamic-textarea"

              value={answersForm.post_Media}
              onChange={handleChange}
              rows={textareaRows3["post_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="inspiration_Media">
              Где ищешь вдохновение? (пинтерест, тикток, вк, и т.п)
            </label>
            <textarea
              id="inspiration_Media"
              name="inspiration_Media"
              className="dynamic-textarea"

              value={answersForm.inspiration_Media}
              onChange={handleChange}
              rows={textareaRows3["inspiration_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="photos_Media">
              Какой фото и видео формат можно выпускать в группу ITC?
            </label>
            <textarea
              id="photos_Media"
              name="photos_Media"
              className="dynamic-textarea"

              value={answersForm.photos_Media}
              onChange={handleChange}
              rows={textareaRows3["photos_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="work_Media">
              Расскажи про свои сильные и слабые стороны в работе
            </label>
            <textarea
              id="work_Media"
              name="work_Media"
              className="dynamic-textarea"

              value={answersForm.work_Media}
              onChange={handleChange}
              rows={textareaRows3["work_Media"] || 1}
              style={{ resize: "none" }}
            />
          </div>
            <div style={{ color: "red"}}>{isEmptyField && error}
            </div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button className="form__button mediaButton" onClick={handleBack}>
              Назад
            </button>
            {showSubmitButton ? ( // Показываем кнопку "Отправить" только если showSubmitButton === true
        <button className="form__button mediaButton" type="submit">
            Отправить
        </button>
    ) : (
        <button className="form__button mediaButton" onClick={handleNext}>
            Далее
        </button>
    )}
          </div>
        </form>
        <div className="running-text-container" id="running-text-container">
          <pre className="text">
            <code>
            ITC СНОВА СДЕЛАЛ КРАСИВО
            </code>
          </pre>
        </div>
      </div>
    </>
  );
};

export default FormMedia;
