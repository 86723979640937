import React, { useEffect, useState } from "react";
import logo from "../../images/ITC_LOGO.png";
import heart from "../../images/heart.svg";
import itc from "../../images/itc.png"

const MainPage = ({ goQuestions }) => {
  const [currentLine, setCurrentLine] = useState(-1); // Изначально не показываем ни одной строки

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentLine((prevLine) => prevLine + 1);
    }, 400); // Интервал между каждой печатаемой строкой

    return () => clearInterval(interval); // Очистка интервала при размонтировании компонента
  }, []);

  return (
    <>
      <div className="main-page">
      <img src={logo} className="main__logo" />
          <div className="main__text-block">
            <h1 className="main__header">Набор в ITC</h1>
            <h2 className="main__p">Приветствуем тебя на анкетировании!</h2>
          </div>
        <div className="code-container" id="codeContainer">
          <pre className="code">
            <code>
              {currentLine >= 1 && (
                <p className="code-line">print("ITC снова сделал красиво")</p>
              )}
              {currentLine >= 2 && <p className="code-line">While ITCenter:</p>}
              {currentLine >= 3 && (
                <p className="code-line">---SOVET = ITCENTER</p>
              )}
              {currentLine >= 4 && (
                <p className="code-line">if base == family:</p>
              )}
              {currentLine >= 5 && (
                <p className="code-line">
                  ---life = ["HARDWORK", "coffee", "sleep", "content", "jawsspot"]
                </p>
              )}
              {currentLine >= 6 && <p className="code-line">else:</p>}
              {currentLine >= 7 && (
                <p className="code-line">---life = "pointless"</p>
              )}
              {currentLine >= 8 && <p className="code-line">---break</p>}
            </code>
          </pre>
        </div>

        <section className="about__block">
          <div className="block__section">
            <h2 className="about__title">О НАБОРЕ</h2>
            <p className="about__text">
              Мы долго этого ждали и оно случилось! Набор в нашу структуру
              начался!
            </p>
            <p className="about__text">
              Далее тебе нужно будет заполнить гугл форму, отвечай честно и
              искренне. Обрати внимание, что тебе нужно заполнить форму в
              зависимости от выбранной тобой структуры.
            </p>
            <p className="about__text">
            Можно выбрать несколько структур и заполнить анкету сразу на несколько направлений, если
              ты еще не определился(лась).
            </p>
            <p className="about__text" style={{ fontWeight:"bold", textShadow: '2px 2px 4px rgb(244 0 255)', color: '#00eb7a'}}>
            ДЕДЛАЙН ДО: 8 мая 23:59
            </p>
          </div>
          <img src={heart} className="heart" />{" "}
        </section>

        <section>
          <h3 className="next__text">
            НУ ЧТО Ж, ПОГНАЛИ) <br/>Желаем удачи! <br/>
            Надеемся, что еще встретимся
          </h3>
          <button onClick={goQuestions} className="button">   поехали        </button>
        </section>
       
        <section className="footer1">
        <img src={itc} className="itc" />{" "}

          <p className="footer__p">© ITC 2024</p>
        </section>
      </div>
    </>
  );
};

export default MainPage;
