import React, { useState } from "react";
import FormFront from "./FormFront";
import FormBack from "./FormBack";
import FormMedia from "./FormMedia";
import FormProject from "./FormProject";
import { useNavigate } from "react-router-dom";
 
const Form = ({ sendDataToBackend }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const mainFormAnswers = JSON.parse(localStorage.getItem("answers"));
  const [selectedStructures, setSelectedStructures] = useState(
    mainFormAnswers.structure
  );
  const navigate = useNavigate();
  const [showSubmitButton, setShowSubmitButton] = useState(false);

  const handleNext = () => {
      if (currentStep < selectedStructures.length - 1) {
          setCurrentStep(currentStep + 1);
          window.scrollTo(0, 0);
      } else {
          setShowSubmitButton(true);
      }
  };

  const handleBack = () => {
    if (currentStep === 0 || currentStep === 1) {
      navigate("/questions");
      window.scrollTo(0, 0);
    } else {
      setCurrentStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };

  const renderForm = () => {
    switch (selectedStructures[currentStep]) {
      case "Backend":
        return (
          <FormBack
            handleNext={handleNext}
            handleBack={handleBack}
            sendDataToBackend={sendDataToBackend} 
            currentStep={currentStep}
            selectedStructures={selectedStructures}
            showSubmitButton={showSubmitButton}
          />
        );
      case "Frontend":
        return (
          <FormFront
            handleNext={handleNext}
            handleBack={handleBack}
            sendDataToBackend={sendDataToBackend} 
            currentStep={currentStep}

          />
        );
      case "Media":
        return (
          <FormMedia
            handleNext={handleNext}
            handleBack={handleBack}
            sendDataToBackend={sendDataToBackend} 
            currentStep={currentStep}

          />
        );
      case "Project":
        return (
          <FormProject
            handleNext={handleNext}
            handleBack={handleBack}
            sendDataToBackend={sendDataToBackend} 
            currentStep={currentStep}

          />
        );
      default:
        return null;
    }
  };

  return <div>{renderForm()}</div>;
};

export default Form;
