import React, { useEffect, useState } from "react";

const FormProject = ({
  sendDataToBackend,
  handleNext,
  handleBack,
  currentStep,
}) => {
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState("");
  const [isEmptyField, setIsEmptyField] = useState(false);
  const [textareaRows4, setTextAreaRows4] = useState({});

  const mainFormAnswers = JSON.parse(localStorage.getItem("answers"));
  const [selectedStructures, setSelectedStructures] = useState(
    mainFormAnswers ? mainFormAnswers.structure : []
  );
  const [showSubmitButton, setShowSubmitButton] = useState(false); // Добавляем состояние для отображения кнопки "Отправить"

  useEffect(() => {
    if (currentStep === selectedStructures.length - 1) {
      setShowSubmitButton(true);
    } else {
      setShowSubmitButton(false);
    }
  }, [currentStep, selectedStructures]);
  const [answersForm, setAnswersForm] = useState(() => {
    const storedAnswers = localStorage.getItem("answersForm");
    return storedAnswers
      ? JSON.parse(storedAnswers)
      : {
          reasonsWhy_Project: "",
          experience_Project: "",
          aboutExperience_Project: "",
          responsibilities_Project: "",
          interaction_Project: "",
          development_Project: "",
          futureProjects_Project: "",
          work_Project: "",
        };
  });

  const handleChange = (e) => {
    const { name, value, scrollHeight, clientHeight } = e.target;
    const uniqueId = e.target.id;

    setAnswersForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (scrollHeight > clientHeight) {
      e.target.rows += 1;
    }

    localStorage.setItem(
      "answersForm",
      JSON.stringify({
        ...answersForm,
        [name]: value,
      })
    );
    setTextAreaRows4((prevState) => ({
      ...prevState,
      [uniqueId]: e.target.rows,
    }));
    localStorage.setItem(
      "textareaRows4",
      JSON.stringify({ ...textareaRows4, [uniqueId]: e.target.rows })
    );  };

  useEffect(() => {
    const savedRows = localStorage.getItem("textareaRows4");
    if (savedRows) {
      const textareas = document.querySelectorAll(".dynamic-textarea");
      textareas.forEach((textarea) => {
        const name = textarea.getAttribute("name");
        textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1);
      });
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(answersForm).filter(
      (key) =>
        typeof answersForm[key] === "string" && answersForm[key].trim() === ""
    );

    if (emptyFields.length > 0) {
      setError("Заполните все поля");
      setIsEmptyField(true); // Установка флага, если есть пустые поля
    } else {
      setError("");
      if (currentStep === selectedStructures.length - 1) {
        sendDataToBackend();
        setSubmited(true);
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      <div className="Form Form_Project">
        <form onSubmit={handleSubmit} className="survival-school-form">
          <h2 className="p_Project">Вопросы на Project</h2>

          <div>
            <label htmlFor="reasonsWhy_Project">
              Почему ты хочешь в проджект?
            </label>
            <textarea
              id="reasonsWhy_Project"
              name="reasonsWhy_Project"
              className="dynamic-textarea"
              value={answersForm.reasonsWhy_Project}
              onChange={handleChange}
              rows={textareaRows4["reasonsWhy_Project"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="experience_Project">
              Есть ли у тебя опыт, связанный с проджект менеджментом или
              организацией чего-либо?
            </label>
            <div
              style={{
                display: "flex",
                marginBottom: "0",
                height: "30px",
                maxWidth: "50px",
                alignItems: 'center'
              }}
            >
              <input
                type="checkbox"
                id="experience_Project_yes"
                name="experience_Project"
                className="experience_Project custom-checkbox4"
                value="Да"
                checked={answersForm.experience_Project === "Да"}
                onChange={handleChange}
                style={{
                  fontSize: "14px",
                  marginRight: "5px",
                  width: "20px",
                }}
              />
              <p
                htmlFor="experience_Project"
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  width: "20px",
                }}
              >
                Да
              </p>
            </div>
            <div
              style={{
                display: "flex",
                marginBottom: "0",
                maxWidth: "50px",
                height: "30px",
                alignItems: 'center'
              }}
            >
              <input
                type="checkbox"
                id="experience_Project_no"
                className="experience_Project custom-checkbox4"
                name="experience_Project"
                value="Нет"
                checked={answersForm.experience_Project === "Нет"}
                onChange={handleChange}
                style={{ marginRight: "5px", fontSize: "14px", width: "20px" }}
              />
              <p
                htmlFor="experience_Project_no"
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  width: "20px",
                }}
              >
                Нет
              </p>
            </div>
          </div>

          <div>
            <label htmlFor="aboutExperience_Project">
              Если да, то опиши его
            </label>
            <textarea
              id="aboutExperience_Project"
              name="aboutExperience_Project"
              className="dynamic-textarea"
              value={answersForm.aboutExperience_Project}
              onChange={handleChange}
              rows={textareaRows4["aboutExperience_Project"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="responsibilities_Project">
              Чем по-твоему занимается project в ITC?
            </label>
            <textarea
              id="responsibilities_Project"
              name="responsibilities_Project"
              value={answersForm.responsibilities_Project}
              onChange={handleChange}
              rows={textareaRows4["responsibilities_Project"] || 1}
              className="dynamic-textarea"
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="interaction_Project">
              Опиши процесс взаимодействия проджекта с другими структурами
              (back, front, media)
            </label>
            <textarea
              id="interaction_Project"
              name="interaction_Project"
              value={answersForm.interaction_Project}
              onChange={handleChange}
              rows={textareaRows4["interaction_Project"] || 1}
              className="dynamic-textarea"
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="development_Project">
              Какое ты видишь развитие у IT-Center? Приведи примеры продвижения
            </label>
            <textarea
              id="development_Project"
              name="development_Project"
              value={answersForm.development_Project}
              onChange={handleChange}
              className="dynamic-textarea"
              rows={textareaRows4["development_Project"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="futureProjects_Project">
              Какие проекты ты мечтаешь реализовать? С чем они связаны и в чем
              их суть?
            </label>
            <textarea
              id="futureProjects_Project"
              name="futureProjects_Project"
              className="dynamic-textarea"
              value={answersForm.futureProjects_Project}
              onChange={handleChange}
              rows={textareaRows4["futureProjects_Project"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="work_Project">
              Расскажи про свои сильные и слабые стороны в работе
            </label>
            <textarea
              id="work_Project"
              name="work_Project"
              className="dynamic-textarea"
              value={answersForm.work_Project}
              onChange={handleChange}
              rows={textareaRows4["work_Project"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div style={{ color: "red" }}>{isEmptyField && error}</div>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button className="form__button projectButton" onClick={handleBack}>
              Назад
            </button>
            {showSubmitButton ? ( // Показываем кнопку "Отправить" только если showSubmitButton === true
              <button className="form__button projectButton" type="submit">
                Отправить
              </button>
            ) : (
              <button
                className="form__button projectButton"
                onClick={handleNext}
              >
                Далее
              </button>
            )}
          </div>
        </form>
        <div className="running-text-container" id="running-text-container">
          <pre className="text">
            <code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
          </pre>
        </div>
      </div>
    </>
  );
};

export default FormProject;
