import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// Получаем все элементы input и textarea на страниц
const FormBack = ({
  handleNext,
  handleBack,
  sendDataToBackend,
  currentStep,
  selectedStructures,
}) => {
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState("");
  const mainFormAnswers = JSON.parse(localStorage.getItem("answers"));
  const [textareaRows, setTextAreaRows] = useState({});

  const [showSubmitButton, setShowSubmitButton] = useState(false); // Добавляем состояние для отображения кнопки "Отправить"

  useEffect(() => {
    if (currentStep === selectedStructures.length - 1) {
      setShowSubmitButton(true);
    } else {
      setShowSubmitButton(false);
    }
  }, [currentStep, selectedStructures]);

  const [answersForm, setAnswersForm] = useState(() => {
    const storedAnswers = localStorage.getItem("answersForm");
    return storedAnswers
      ? JSON.parse(storedAnswers)
      : {
          experience_Back: "",
          reasonsWhy_Back: "",
          languages_Back: {},
          moreLanguages_Back: "",
          frameworks_Back: "",
          projects_Back: "",
          futureProjects_Back: "",
          work_Back: "",
        };
  });
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);

  const handleChange = (e) => {
    const { name, value, scrollHeight, clientHeight } = e.target;
    const uniqueId = e.target.id;

    if (scrollHeight > clientHeight) {
      e.target.rows += 1;
    }
    // Обновите состояние каждой textarea, используя уникальный идентификатор
    if (e.target.type === "radio") {
      setAnswersForm((prevState) => ({
        ...prevState,
        languages_Back: {
          ...prevState.languages_Back,
          [name]: value,
        },
      }));
    } else {
      setAnswersForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }

    

    // Обновите количество строк в каждой textarea
    setTextAreaRows((prevState) => ({
      ...prevState,
      [uniqueId]: e.target.rows,
    }));

    // localStorage.setItem("textareaRows", JSON.stringify(textAreaRows));
    localStorage.setItem(
      "textareaRows",
      JSON.stringify({ ...textareaRows, [uniqueId]: e.target.rows })
    );
    localStorage.setItem("answersForm", JSON.stringify(answersForm));
  };

  useEffect(() => {
    const savedRows = localStorage.getItem("textareaRows");
    if (savedRows) {
      const textareas = document.querySelectorAll(".dynamic-textarea");
      textareas.forEach((textarea) => {
        const name = textarea.getAttribute("name");
        textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1);
      });
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("answersForm", JSON.stringify(answersForm));
  }, [answersForm]);

  useEffect(() => {
    const storedAnswers = localStorage.getItem("answersForm");
    if (storedAnswers) {
      setAnswersForm(JSON.parse(storedAnswers));
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(answersForm).filter(
      (key) =>
        typeof answersForm[key] === "string" && answersForm[key].trim() === ""
    );
    setIsFormIncomplete(emptyFields.length > 0);
    if (emptyFields.length > 0) {
      setError("Заполните все поля");
    } else {
      if (currentStep === selectedStructures.length - 1) {
        sendDataToBackend();
        setSubmited(true);
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      <div className="Form Form_Backend">
        <form onSubmit={handleSubmit} className="survival-school-form">
          <h2 className="p_Back">Вопросы на Backend</h2>

          <div>
            <label htmlFor="experience_Back">
              Расскажи про свой опыт работы с бэкендом
            </label>
            <textarea
              id="experience_Back"
              name="experience_Back"
              className="dynamic-textarea"
              value={answersForm.experience_Back}
              onChange={handleChange}
              rows={textareaRows["experience_Back"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="reasonsWhy_Back">
              Почему ты выбрал(а) именно эту структуру?
            </label>
            <textarea
              id="reasonsWhy_Back"
              name="reasonsWhy_Back"
              className="dynamic-textarea"
              value={answersForm.reasonsWhy_Back}
              onChange={handleChange}
              rows={textareaRows["reasonsWhy_Back"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="languages_Back">
              Оцени свое владение языками программирования по шкале от 0 до 5
            </label>

            <table>
              <tr>
                <th className="th_back">Язык</th>
                <th className="th_back">0</th>
                <th className="th_back">1</th>
                <th className="th_back">2</th>
                <th className="th_back">3</th>
                <th className="th_back">4</th>
                <th className="th_back">5</th>
              </tr>
              <tr>
                <td className="td_back">Python</td>
                <td>
                  <input
                    type="radio"
                    id="python_0"
                    name="python"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                 
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="python_1"
                    name="python"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="python_2"
                    name="python"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="python_3"
                    name="python"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="python_4"
                    name="python"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="python_5"
                    name="python"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
              <tr>
                <td>C#</td>
                <td>
                  <input
                    type="radio"
                    id="csharp_0"
                    name="csharp"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="csharp_1"
                    name="csharp"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="csharp_2"
                    name="csharp"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="csharp_3"
                    name="csharp"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="csharp_4"
                    name="csharp"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="csharp_5"
                    name="csharp"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
              <tr>
                <td>C++</td>
                <td>
                  <input
                    type="radio"
                    id="cplusplus_0"
                    name="cplusplus"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="cplusplus_1"
                    name="cplusplus"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="cplusplus_2"
                    name="cplusplus"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="cplusplus_3"
                    name="cplusplus"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="cplusplus_4"
                    name="cplusplus"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="cplusplus_5"
                    name="cplusplus"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
              <tr>
                <td className="td_back">PHP</td>
                <td>
                  <input
                    type="radio"
                    id="php_0"
                    name="php"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="php_1"
                    name="php"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="php_2"
                    name="php"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="php_3"
                    name="php"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="php_4"
                    name="php"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="php_5"
                    name="php"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
              <tr>
                <td className="td_back">Java</td>
                <td>
                  <input
                    type="radio"
                    id="java_0"
                    name="java"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="java_1"
                    name="java"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="java_2"
                    name="java"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="java_3"
                    name="java"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="java_4"
                    name="java"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="java_5"
                    name="java"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
              <tr>
                <td className="td_back">Pascal</td>
                <td>
                  <input
                    type="radio"
                    id="pascal_0"
                    name="pascal"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="pascal_1"
                    name="pascal"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="pascal_2"
                    name="pascal"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="pascal_3"
                    name="pascal"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="pascal_4"
                    name="pascal"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="pascal_5"
                    name="pascal"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
              <tr>
                <td className="td_back">Go</td>
                <td>
                  <input
                    type="radio"
                    id="go_0"
                    name="go"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="go_1"
                    name="go"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="go_2"
                    name="go"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="go_3"
                    name="go"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="go_4"
                    name="go"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="go_5"
                    name="go"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
              <tr>
                <td className="td_back">Rust</td>
                <td>
                  <input
                    type="radio"
                    id="rust_0"
                    name="rust"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="rust_1"
                    name="rust"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="rust_2"
                    name="rust"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="rust_3"
                    name="rust"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="rust_4"
                    name="rust"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="rust_5"
                    name="rust"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox2"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div>
            <label htmlFor="moreLanguages_Back">
              Если ты знаешь другие языки программирования, напиши уровень
              владения ими ниже (Например, Assembler: 2)
            </label>
            <textarea
              id="moreLanguages_Back"
              name="moreLanguages_Back"
              className="dynamic-textarea"
              value={answersForm.moreLanguages_Back}
              onChange={handleChange}
              rows={textareaRows["moreLanguages_Back"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="frameworks_Back">
              Расскажи с какими фреймворками ты работал(а) и про свой основной
              стек в целом
            </label>
            <textarea
              id="frameworks_Back"
              name="frameworks_Back"
              className="dynamic-textarea"
              value={answersForm.frameworks_Back}
              onChange={handleChange}
              rows={textareaRows["frameworks_Back"] || 1}
              style={{ resize: "none" }}
            />
          </div>

          <div>
            <label htmlFor="projects_Back">
              Реализовывал(а) ли ты какие-нибудь проекты, если да, то опиши в
              чем суть и прикрепи ссылку на GitHub/GitLab/иной файлообменник
              Если нет, напиши нет либо поставь прочерк
            </label>
            <textarea
              id="projects_Back"
              name="projects_Back"
              className="dynamic-textarea"
              value={answersForm.projects_Back}
              onChange={handleChange}
              rows={textareaRows["projects_Back"] || 1}
              style={{ resize: "none" }}
            />
          </div>

          <div>
            <label htmlFor="futureProjects_Back">
              Какой(ие) проект(ы) ты мечтаешь реализовать? С чем он(и) связан(ы)
              и в чем его(их) суть?
            </label>
            <textarea
              id="futureProjects_Back"
              name="futureProjects_Back"
              className="dynamic-textarea"
              value={answersForm.futureProjects_Back}
              onChange={handleChange}
              rows={textareaRows["futureProjects_Back"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="work_Back">
              Расскажи про свои сильные и слабые стороны в работе
            </label>
            <textarea
              id="work_Back"
              name="work_Back"
              className="dynamic-textarea"
              value={answersForm.work_Back}
              onChange={handleChange}
              rows={textareaRows["work_Back"] || 1}
              style={{ resize: "none" }}
            />
          </div>

          <div style={{ color: "red" }}>{isFormIncomplete && error}</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button className="form__button backendButton" onClick={handleBack}>
              Назад
            </button>
            {showSubmitButton ? (
              <button className="form__button backendButton" type="submit">
                Отправить
              </button>
            ) : (
              <button
                className="form__button backendButton"
                onClick={handleNext}
              >
                Далее
              </button>
            )}
          </div>
        </form>
        <div className="running-text-container" id="running-text-container">
          <pre className="text">
            <code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
          </pre>
        </div>
      </div>
    </>
  );
};

export default FormBack;
