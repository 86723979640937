import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const FormFront = ({
  sendDataToBackend,
  handleNext,
  handleBack,
  currentStep,
}) => {
  const [submited, setSubmited] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const mainFormAnswers = JSON.parse(localStorage.getItem("answers"));
  const [selectedStructures, setSelectedStructures] = useState(
    mainFormAnswers ? mainFormAnswers.structure : []
  );
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);
  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const [textareaRows2, setTextAreaRows2] = useState({});

  useEffect(() => {
    if (currentStep === selectedStructures.length - 1) {
      setShowSubmitButton(true);
    } else {
      setShowSubmitButton(false);
    }
  }, [currentStep, selectedStructures]);
  const [answersForm, setAnswersForm] = useState(() => {
    const storedAnswers = localStorage.getItem("answersForm");
    return storedAnswers
      ? JSON.parse(storedAnswers)
      : {
          projects_Front: "",
          languages_Front: {},
          moreLanguages_Front: "",
          frameworks_Front: "",
          figma_Front: "",
          trends_Front: "",
          futureProjects_Front: "",
          work_Front: "",
        };
  });

  const handleChange = (e) => {
    const { name, value, scrollHeight, clientHeight } = e.target;
    const uniqueId = e.target.id;
    if (scrollHeight > clientHeight) {
      e.target.rows += 1;
    }
    if (e.target.type === "radio") {
      setAnswersForm((prevState) => ({
        ...prevState,
        languages_Front: {
          ...prevState.languages_Front,
          [name]: value,
        },
      }));
    } else {
      setAnswersForm((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    // Обновите количество строк в каждой textarea
    setTextAreaRows2((prevState) => ({
      ...prevState,
      [uniqueId]: e.target.rows,
    }));
    localStorage.setItem(
      "textareaRows2",
      JSON.stringify({ ...textareaRows2, [uniqueId]: e.target.rows })
    );    localStorage.setItem("answersForm", JSON.stringify(answersForm));
  };

  useEffect(() => {
    const savedRows = localStorage.getItem("textareaRows2");
    if (savedRows) {
      const textareas = document.querySelectorAll(".dynamic-textarea");
      textareas.forEach((textarea) => {
        const name = textarea.getAttribute("name");
        textarea.rows = parseInt(JSON.parse(savedRows)[name] || 1);
      });
    }
  }, []);

  useEffect(() => {
    const storedAnswers = localStorage.getItem("answersForm");
    if (storedAnswers) {
      setAnswersForm(JSON.parse(storedAnswers));
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const emptyFields = Object.keys(answersForm).filter(
      (key) =>
        typeof answersForm[key] === "string" && answersForm[key].trim() === ""
    );

    setIsFormIncomplete(emptyFields.length > 0);
    if (emptyFields.length > 0) {
      setError("Заполните все поля");
    } else {
      /*const updatedAnswersForm = {
        ...answersForm,
      };
      localStorage.setItem("answersForm", JSON.stringify(updatedAnswersForm));
      if (currentStep === selectedStructures.length - 1) {
        sendDataToBackend();
        setSubmited(true);
      } else {
        handleNext();
      }*/
      if (currentStep === selectedStructures.length - 1) {
        sendDataToBackend();
        setSubmited(true);
      } else {
        handleNext();
      }
    }
  };

  return (
    <>
      <div className="Form Form_Frontend">
        <form onSubmit={handleSubmit} className="survival-school-form">
          <h2 className="p_Front">Вопросы на Frontend </h2>

          <div>
            <label htmlFor="projects_Front">
              Расскажи о своем опыте работы с фронтендом. Какие проекты удалось
              реализовать? Если есть проекты, прикрепи ссылки на них
              (GitHub/GitLab/иной файлообменник) и опиши их суть
            </label>
            <textarea
              id="projects_Front"
              name="projects_Front"
              className="dynamic-textarea"
              value={answersForm.projects_Front}
              onChange={handleChange}
              rows={textareaRows2["projects_Front"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="languages_Front">
              Оцени свое владение основными языками и инструментами по шкале от
              0 до 5
            </label>
            <table>
              <tr>
                <th style={{ fontSize: "14px" }}>Язык / Инструмент</th>
                <th>0</th>
                <th>1</th>
                <th>2</th>
                <th>3</th>
                <th>4</th>
                <th>5</th>
              </tr>
              <tr>
                <td>HTML</td>
                <td>
                  <input
                    type="radio"
                    id="html_0"
                    name="html"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="html_1"
                    name="html"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="html_2"
                    name="html"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="html_3"
                    name="html"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="html_4"
                    name="html"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="html_5"
                    name="html"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
              <tr>
                <td>CSS</td>
                <td>
                  <input
                    type="radio"
                    id="css_0"
                    name="css"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="css_1"
                    name="css"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="css_2"
                    name="css"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="css_3"
                    name="css"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="css_4"
                    name="css"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="css_5"
                    name="css"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
              <tr>
                <td>JavaScript</td>
                <td>
                  <input
                    type="radio"
                    id="javascript_0"
                    name="javascript"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="javascript_1"
                    name="javascript"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="javascript_2"
                    name="javascript"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="javascript_3"
                    name="javascript"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="javascript_4"
                    name="javascript"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="javascript_5"
                    name="javascript"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
              <tr>
                <td>React</td>
                <td>
                  <input
                    type="radio"
                    id="react_0"
                    name="react"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="react_1"
                    name="react"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="react_2"
                    name="react"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="react_3"
                    name="react"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="react_4"
                    name="react"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="react_5"
                    name="react"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
              <tr>
                <td>Webpack</td>
                <td>
                  <input
                    type="radio"
                    id="webpack_0"
                    name="webpack"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="webpack_1"
                    name="webpack"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="webpack_2"
                    name="webpack"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="webpack_3"
                    name="webpack"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="webpack_4"
                    name="webpack"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="webpack_5"
                    name="webpack"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
              <tr>
                <td>Git</td>
                <td>
                  <input
                    type="radio"
                    id="git_0"
                    name="git"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="git_1"
                    name="git"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="git_2"
                    name="git"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="git_3"
                    name="git"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="git_4"
                    name="git"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="git_5"
                    name="git"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
              <tr>
                <td>Rest API</td>
                <td>
                  <input
                    type="radio"
                    id="restapi_0"
                    name="restapi"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="restapi_1"
                    name="restapi"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="restapi_2"
                    name="restapi"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="restapi_3"
                    name="restapi"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="restapi_4"
                    name="restapi"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="restapi_5"
                    name="restapi"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
              <tr>
                <td>NPM</td>
                <td>
                  <input
                    type="radio"
                    id="npm_0"
                    name="npm"
                    value="0"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="npm_1"
                    name="npm"
                    value="1"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="npm_2"
                    name="npm"
                    value="2"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="npm_3"
                    name="npm"
                    value="3"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="npm_4"
                    name="npm"
                    value="4"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
                <td>
                  <input
                    type="radio"
                    id="npm_5"
                    name="npm"
                    value="5"
                    onChange={handleChange}
                    className="input_th custom-checkbox3"
                  />
                </td>
              </tr>
            </table>
          </div>
          <div>
            <label htmlFor="moreLanguages_Front">
              Если ты знаешь другие языки/инструменты, напиши уровень владения
              ими ниже (например, TypeScript: 3)
            </label>
            <textarea
              id="moreLanguages_Front"
              name="moreLanguages_Front"
              className="dynamic-textarea"
              value={answersForm.moreLanguages_Front}
              onChange={handleChange}
              rows={textareaRows2["moreLanguages_Front"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="frameworks_Front">
              С какими фреймворками и библиотеками ты имел(а) дело?
            </label>
            <textarea
              id="frameworks_Front"
              name="frameworks_Front"
              className="dynamic-textarea"
              value={answersForm.frameworks_Front}
              onChange={handleChange}
              rows={textareaRows2["frameworks_Front"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="figma_Front">Работал(а) ли ты с figma?</label>
            <textarea
              id="figma_Front"
              name="figma_Front"
              className="dynamic-textarea"
              value={answersForm.figma_Front}
              onChange={handleChange}
              rows={textareaRows2["figma_Front"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="trends_Front">
              Какие фронтенд-тренды и новые технологии тебя интересуют и ты
              хотел(а) бы изучить в будущем?
            </label>
            <textarea
              id="trends_Front"
              name="trends_Front"
              className="dynamic-textarea"
              value={answersForm.trends_Front}
              onChange={handleChange}
              rows={textareaRows2["trends_Front"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="futureProjects_Front">
              Какой(ие) проект(ы) ты мечтаешь реализовать? С чем он(и) связан(ы)
              и в чем его(их) суть?
            </label>
            <textarea
              id="futureProjects_Front"
              name="futureProjects_Front"
              className="dynamic-textarea"
              value={answersForm.futureProjects_Front}
              onChange={handleChange}
              rows={textareaRows2["futureProjects_Front"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div>
            <label htmlFor="work_Front">
              Расскажи про свои сильные и слабые стороны в работе
            </label>
            <textarea
              id="work_Front"
              name="work_Front"
              className="dynamic-textarea"
              value={answersForm.work_Front}
              onChange={handleChange}
              rows={textareaRows2["work_Front"] || 1}
              style={{ resize: "none" }}
            />
          </div>
          <div style={{ color: "red" }}>{isFormIncomplete && error}</div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <button className="form__button frontButton" onClick={handleBack}>
              Назад
            </button>
            {showSubmitButton ? ( // Показываем кнопку "Отправить" только если showSubmitButton === true
              <button className="form__button frontButton" type="submit">
                Отправить
              </button>
            ) : (
              <button className="form__button frontButton" onClick={handleNext}>
                Далее
              </button>
            )}
          </div>
        </form>
        <div className="running-text-container" id="running-text-container">
          <pre className="text">
            <code>ITC СНОВА СДЕЛАЛ КРАСИВО</code>
          </pre>
        </div>
      </div>
    </>
  );
};

export default FormFront;
